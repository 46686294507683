import React, { useState, useEffect } from 'react'
import '../styles/HomePage.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import { Link as RouterLink } from 'react-router-dom'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import menuIcon from '../assets/images/icons/icon-open-menu.svg'
import heartIcon from '../assets/images/icons/icon-heart-light.svg'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'
// import iconTrash from '../assets/images/icons/icon-trash.svg'
import productSmall1 from '../assets/images/products/small/product-small-1.webp'
import productSmall2 from '../assets/images/products/small/product-small-2.webp'
import productSmall3 from '../assets/images/products/small/product-small-3.webp'

// dependencies
import { Link } from 'react-router-dom'
import { Offcanvas, Button } from 'react-bootstrap'

// Image
import heroImage1 from '../assets/images/hero-slider/hero-slider-style-2-img-1.png'
import heroImage2 from '../assets/images/hero-slider/hero-slider-style-2-img-2.png'
import heroImage3 from '../assets/images/hero-slider/hero-slider-style-2-img-3.png'

//Slider
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

//
const images = [heroImage1, heroImage2, heroImage3]

function HomePage() {
  const [showOffcanvas, setShowOffcanvas] = useState(false)
  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas)

  //

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  //

  const wishlistItems = [
    { id: 1, title: 'Tops', price: '$100.00', imgSrc: productSmall1 },
    { id: 2, title: 'Leggings', price: '$49.00', imgSrc: productSmall2 },
    { id: 3, title: 'Casual Shirt', price: '$65.00', imgSrc: productSmall3 },
  ]

  //

  const [cartItems, setCartItems] = useState([])

  const closeOffcanvas = () => {
    // اجرای عملیات بستن offcanvas
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1))
  }

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1))
  }

  //

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  {isLoggedIn ? (
                    <Link
                      to='/my-account'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Profile
                    </Link>
                  ) : (
                    <Link
                      to='/login-register'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Log In
                    </Link>
                  )}
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- .....:::::: Start Mobile Header Section :::::.... --> */}
      <div className='mobile-header d-block d-lg-none'>
        <div className='container-fluid'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-auto'>
              <div className='mobile-logo'>
                <Link to='/'>
                  <img src={logoImage} alt='Logo' />
                </Link>
              </div>
            </div>
            <div className='col-auto'>
              <div className='mobile-action-link text-end d-flex'>
                <Button variant='primary' onClick={toggleOffcanvas}>
                  <img src={menuIcon} alt='Menu' />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          show={showOffcanvas}
          onHide={toggleOffcanvas}
          placement='end'
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {' '}
            <ul className='offcanvas-menu'>
              <li>
                <Link to='/' onClick={() => setShowOffcanvas(false)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to='/shop' onClick={() => setShowOffcanvas(false)}>
                  Shop
                </Link>
              </li>
              <li>
                <Link to='/blog' onClick={() => setShowOffcanvas(false)}>
                  Blog
                </Link>
                {/* در صورت نیاز به زیرمنو */}
                <ul>
                  <li>
                    <Link
                      to='/blog/category1'
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Category 1
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/blog/category2'
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Category 2
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to='/about' onClick={() => setShowOffcanvas(false)}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to='/contact' onClick={() => setShowOffcanvas(false)}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {/* <!-- .....:::::: Start MobileHeader Section :::::.... --> */}

      {/* <!-- ...::: Start Hero Slider Section :::... --> */}
      <div className='minimal-slider-container'>
        <div className='minimal-slider'>
          <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} />
          <div className='slider-overlay'>
            <button className='slider-button prev' onClick={handlePrevSlide}>
              &#8249;
            </button>
            <button className='slider-button next' onClick={handleNextSlide}>
              &#8250;
            </button>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Hero Slider Section:::... --> */}
      {/* <!-- ...::: Start Banner Portrait Section Section :::... --> */}
      <div className='banner-portrait-section section-fluid-270 section-top-gap-100 pos-relative'>
        <div className='section-wrapper section-orientation-vertical'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='section-content text-uppercase'>
                  <span className='title-tag'>Choose you </span>
                  <h2 className='section-title has-underline'>Categories</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='box-wrapper'>
          <div className='banner-portrait-wrapper'>
            <div className='container-fluid'>
              <div className='row mb-n20'>
                <div className='col-md-3 offset-md-2 col-sm-4 col-12 mb-20'>
                  {/* Start Banner Portrait Single Item */}
                  <div className='banner-animate--flash'>
                    <a
                      href='shop-grid-sidebar-left.html'
                      className='banner-portrait-single-item img-responsive'
                    >
                      <div className='image'>
                        <img
                          className='img-fluid'
                          src='assets/images/banner/portrait-banner-1.webp'
                          alt=''
                        />
                      </div>
                    </a>
                  </div>
                  {/* End Banner Portrait Single Item */}
                </div>
                <div className='col-md-3 col-sm-4 col-12 mb-20'>
                  {/* Start Banner Portrait Single Item */}
                  <div className='banner-animate--flash'>
                    <a
                      href='shop-grid-sidebar-left.html'
                      className='banner-portrait-single-item img-responsive'
                    >
                      <div className='image'>
                        <img
                          className='img-fluid'
                          src='assets/images/banner/portrait-banner-2.webp'
                          alt=''
                        />
                      </div>
                    </a>
                  </div>
                  {/* End Banner Portrait Single Item */}
                </div>
                <div className='col-md-3 col-sm-4 col-12 mb-20'>
                  {/* Start Banner Portrait Single Item */}
                  <div className='banner-animate--flash'>
                    <a
                      href='shop-grid-sidebar-left.html'
                      className='banner-portrait-single-item img-responsive'
                    >
                      <div className='image'>
                        <img
                          className='img-fluid'
                          src='assets/images/banner/portrait-banner-3.webp'
                          alt=''
                        />
                      </div>
                    </a>
                  </div>
                  {/* End Banner Portrait Single Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Banner Portrait Section Section :::... --> */}
      {/* <!-- ...::: Start Shipping Section :::... --> */}
      <div className='shipping-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='section-wrapper'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-xl-4 col-lg-5 col-md-6 col-sm-7 col-10'>
                  <div className='section-content section-content-gap-60 text-center'>
                    <h2 className='section-title fw-bold'>Why we are best</h2>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Start Shipping Wrapper */}
          <div className='shipping-wrapper'>
            <div className='container-fluid'>
              <div className='row justify-content-center justify-content-sm-start mb-n40'>
                <div className='col-lg-3 col-sm-6 col-12 mb-40'>
                  {/* Start Shipping Single Items */}
                  <div className='shipping-single-item--style-2'>
                    <div className='icon'>
                      <img
                        className='img-fluid'
                        src='assets/images/icons/shipping-icon-4.svg'
                        alt=''
                      />
                    </div>
                    <div className='content'>
                      <h4 className='title'>Money Back Guarantee</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  {/* Start Shipping Single Items */}
                </div>
                <div className='col-lg-3 offset-xl-1 col-sm-6 col-12 mb-40'>
                  {/* Start Shipping Single Items */}
                  <div className='shipping-single-item--style-2'>
                    <div className='icon'>
                      <img
                        className='img-fluid'
                        src='assets/images/icons/shipping-icon-5.svg'
                        alt=''
                      />
                    </div>
                    <div className='content'>
                      <h4 className='title'>Take a Great Discount</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  {/* Start Shipping Single Items */}
                </div>
                <div className='col-lg-3 offset-xl-1 col-sm-6 col-12 mb-40'>
                  {/* Start Shipping Single Items */}
                  <div className='shipping-single-item--style-2'>
                    <div className='icon'>
                      <img
                        className='img-fluid'
                        src='assets/images/icons/shipping-icon-6.svg'
                        alt=''
                      />
                    </div>
                    <div className='content'>
                      <h4 className='title'>Free Shipping</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                    </div>
                  </div>
                  {/* Start Shipping Single Items */}
                </div>
              </div>
            </div>
          </div>
          {/* End Shipping Wrapper */}
        </div>
      </div>
      {/* <!-- ...::: End Shipping Section :::... --> */}
      {/* <!-- ...::: Start Banner Mixed Section Section :::... --> */}
      <div className='banner-mixed-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='section-wrapper'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-xl-4 col-lg-5 col-md-6 col-sm-7 col-10'>
                  <div className='section-content section-content-gap-60 text-center'>
                    <h2 className='section-title fw-normal'>
                      <span className='fw-bold'> Discounted </span> Products
                    </h2>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-square-wrapper'>
            <div className='container-fluid'>
              <div className='row mb-n20'>
                <div className='col-xl-6 col-md-6 mb-20'>
                  {/* Start Banner Square Single Item */}
                  <div className='banner-square-single-item banner-animate--flash'>
                    <a
                      href='shop-grid-sidebar-left.html'
                      className='image img-responsive'
                    >
                      <img
                        src='assets/images/banner/banner-squre-large.webp'
                        alt=''
                      />
                    </a>
                  </div>
                  {/* End Banner Square Single Item */}
                </div>
                <div className='col-xl-6 col-md-6'>
                  <div className='row mb-n20'>
                    <div className='col-sm-6 mb-20'>
                      {/* Start Banner Square Single Item */}
                      <div className='banner-square-single-item banner-animate--flash'>
                        <a
                          href='shop-grid-sidebar-left.html'
                          className='image img-responsive'
                        >
                          <img
                            src='assets/images/banner/banner-square-normal-1.webp'
                            alt=''
                          />
                        </a>
                      </div>
                      {/* End Banner Square Single Item */}
                    </div>
                    <div className='col-sm-6 mb-20'>
                      {/* Start Banner Square Single Item */}
                      <div className='banner-square-single-item banner-animate--flash'>
                        <a
                          href='shop-grid-sidebar-left.html'
                          className='image img-responsive'
                        >
                          <img
                            src='assets/images/banner/banner-square-normal-2.webp'
                            alt=''
                          />
                        </a>
                      </div>
                      {/* End Banner Square Single Item */}
                    </div>
                    <div className='col-sm-6 mb-20'>
                      {/* Start Banner Square Single Item */}
                      <div className='banner-square-single-item banner-animate--flash'>
                        <a
                          href='shop-grid-sidebar-left.html'
                          className='image img-responsive'
                        >
                          <img
                            src='assets/images/banner/banner-square-normal-3.webp'
                            alt=''
                          />
                        </a>
                      </div>
                      {/* End Banner Square Single Item */}
                    </div>
                    <div className='col-sm-6 mb-20'>
                      {/* Start Banner Square Single Item */}
                      <div className='banner-square-single-item banner-animate--flash'>
                        <a
                          href='shop-grid-sidebar-left.html'
                          className='image img-responsive'
                        >
                          <img
                            src='assets/images/banner/banner-square-normal-4.webp'
                            alt=''
                          />
                        </a>
                      </div>
                      {/* End Banner Square Single Item */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Banner Mixed Section Section :::... --> */}
      {/* <!-- ...::: Start Product Tab Item Section :::... --> */}
      <div className='product-tab-items-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='section-wrapper d-none'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-xl-4 col-lg-7 col-md-8 col-sm-10'>
                  <div className='section-content section-content-gap-60 text-center'>
                    <h2 className='section-title fw-normal'>
                      Hurry! <br /> Get your
                      <span className='fw-bold'>product</span> now
                    </h2>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='section-wrapper'>
            <div className='container-fluid'>
              <div className='row justify-content-center flex-wrap'>
                <div className='col-xl-4 col-lg-5 col-md-6 col-sm-7 col-10'>
                  <div className='section-content section-content-gap-60 text-center'>
                    <h2 className='section-title'>Products</h2>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='product-tab-item-wrapper'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  {/* Start Product Tab Items */}
                  <ul
                    className='product-tab nav justify-content-center'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link active'
                        data-bs-toggle='tab'
                        data-bs-target='#sofa'
                        type='button'
                      >
                        Sofa
                        <img
                          src='assets/images/icons/product-tab-icon-6.svg'
                          alt=''
                        />
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        data-bs-toggle='tab'
                        data-bs-target='#table'
                        type='button'
                      >
                        Table
                        <img
                          src='assets/images/icons/product-tab-icon-7.svg'
                          alt=''
                        />
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        data-bs-toggle='tab'
                        data-bs-target='#bed'
                        type='button'
                      >
                        Bed
                        <img
                          src='assets/images/icons/product-tab-icon-8.svg'
                          alt=''
                        />
                      </button>
                    </li>
                  </ul>
                  {/* End Product Tab Items */}
                </div>
                <div className='col-12'>
                  {/* <!-- Start Tab Content Items --> */}
                  <div className='tab-content'>
                    {/* <!-- Start Tab Content Single Item --> */}
                    <div
                      className='tab-pane show active tab-animate'
                      id='sofa'
                      role='tabpanel'
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <div className='product-grid-items'>
                            <div className='row mb-n25'>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* Start Product Single Item - Style 2 */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-5.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* End Product Single Item - Style 2 */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-6.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-7.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-8.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-2.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-10.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Tab Content Single Item --> */}
                    {/* <!-- Start Tab Content Single Item --> */}
                    <div
                      className='tab-pane tab-animate'
                      id='table'
                      role='tabpanel'
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <div className='product-grid-items'>
                            <div className='row mb-n25'>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* Start Product Single Item - Style 2 */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-6.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* End Product Single Item - Style 2 */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-1.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-2.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-3.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-4.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-5.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Tab Content Single Item --> */}
                    {/* <!-- Start Tab Content Single Item --> */}
                    <div
                      className='tab-pane tab-animate'
                      id='bed'
                      role='tabpanel'
                    >
                      <div className='row'>
                        <div className='col-12'>
                          <div className='product-grid-items'>
                            <div className='row mb-n25'>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* Start Product Single Item - Style 2 */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-1.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* End Product Single Item - Style 2 */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* Start Product Single Item - Style 2 */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-2.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* End Product Single Item - Style 2 */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* Start Product Single Item - Style 2 */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-3.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* End Product Single Item - Style 2 */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-4.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                              <div className='col-xl-4 col-md-6 mb-25'>
                                {/* <!-- Start Product Single Item - Style 2 --> */}
                                <div className='product-single-item-style-2'>
                                  <div className='image img-responsive'>
                                    <a href=''>
                                      <img
                                        className='img-fluid'
                                        src='assets/images/products/default/product-default-style-1-img-5.webp'
                                        alt=''
                                      />
                                    </a>
                                    <a
                                      href='wishlist.html'
                                      className='event-btn'
                                    >
                                      <span className='material-icons'>
                                        favorite_border
                                      </span>
                                    </a>
                                  </div>
                                  <div className='content'>
                                    <div className='top'>
                                      {/* <span className='catagory'>CHAIR</span> */}
                                      <h4 className='title'>
                                        {/* <a href='product-details-default.html'>
                                          Puff Chair
                                        </a> */}
                                      </h4>
                                      <button className='contact-btn'>
                                        Contact US
                                      </button>
                                    </div>
                                    <div className='bottom'>
                                      <a href='cart.html' className='event-btn'>
                                        <span className='material-icons'>
                                          add_shopping_cart
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- End Product Single Item - Style 2 --> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Tab Content Single Item --> */}
                  </div>
                  {/* <!-- End Tab Content Items --> */}

                  <div className='d-flex justify-content-center'>
                    <a
                      href='/shop-page'
                      className='btn btn-md btn-default btn-section-bottom'
                    >
                      View All Product
                    </a>
                  </div>

                  {/* <!-- ...::: Strat Banner Card Section Section :::... --> */}
                  <div className='banner-card-section section-fluid-270 section-top-gap-100'>
                    <div className='box-wrapper'>
                      <div className='banner-car-wrapper'>
                        <div className='container-fluid'>
                          <div className='row'>
                            <div className='col-12'>
                              <a
                                href='shop-grid-sidebar-left.html'
                                className='d-block banner-animate--wave'
                              >
                                <img
                                  className='img-fluid'
                                  src='assets/images/banner/banner-wide-2.webp'
                                  alt=''
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ...::: End Banner Card Section Section :::... --> */}
                  {/* <!-- ...::: Strat Subscribe Section :::... --> */}
                  <div className='newsletter-section section-fluid-270 section-top-gap-100'>
                    <div className='box-wrapper'>
                      <div className='newsletter-wrapper'>
                        <div className='container-fluid'>
                          <div className='col-12'>
                            <div className='newsletter-area newsletter-area-style-2'>
                              <div className='content content-left'>
                                <h4 className='title'>Newsletter</h4>
                                <p>
                                  To contact support and receive our latest
                                  updates, <br />
                                  please enter your email so that we can contact
                                  you.
                                </p>
                                <div className='newsletter-form'>
                                  <input
                                    type='email'
                                    placeholder='Enter your email'
                                    required
                                  />
                                  <button type='submit'>Subscribe</button>
                                </div>
                                <div className='image-shape image-shape-left'>
                                  <img
                                    className='img-fluid'
                                    src='assets/images/icons/newsletter-paper-plane.svg'
                                    alt=''
                                  />
                                </div>
                              </div>
                              <div className='content content-right'>
                                <h4 className='title'>Download App</h4>
                                <p>
                                  You can download our app on different <br />
                                  platforms by following the links below.
                                </p>

                                <div className='apps-btn'>
                                  <a href='/'>
                                    <img
                                      className='img-fluid'
                                      src='assets/images/icons/iphone-app-button.png'
                                      alt=''
                                    />
                                  </a>
                                  <a href='/'>
                                    <img
                                      className='img-fluid'
                                      src='assets/images/icons/googleplay-app-button.png'
                                      alt=''
                                    />
                                  </a>
                                </div>
                                <div className='image-shape image-shape-right'>
                                  <img
                                    className='img-fluid'
                                    src='assets/images/icons/newsletter-cloud-computing.svg'
                                    alt=''
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ...::: End Subscribe Section :::... --> */}
                  {/* <!-- ...::: Strat Footer Section - Footer Dark :::... --> */}
                  <footer className='footer-section footer-section-style-2 section-top-gap-120'>
                    <div className='box-wrapper'>
                      <div className='footer-wrapper section-fluid-270'>
                        <div className='container-fluid'>
                          {/* Start Footer Top */}
                          <div className='footer-top'>
                            <div className='footer-top-left'>
                              <div className='footer-contact-items'>
                                <a
                                  className='icon-left'
                                  href='tel:+12345678910'
                                >
                                  <img
                                    className='icon-svg'
                                    src='assets/images/icons/icon-ios-call-dark.svg'
                                    alt=''
                                  />
                                  +123 4567 8910
                                </a>
                                <a
                                  className='icon-left'
                                  href='mailto:demo@example.com'
                                >
                                  <img
                                    className='icon-svg'
                                    src='assets/images/icons/icon-mail-open-dark.svg'
                                    alt=''
                                  />
                                  info@ecofurnify.com
                                </a>
                              </div>
                            </div>
                            <div className='footer-top-right'>
                              <div className='footer-social'>
                                <a
                                  href='https://twitter.com/ecofurnify'
                                  target='_blank'
                                  rel='noopener'
                                >
                                  <FaTwitter className='social-icon' />
                                </a>
                                <a
                                  href='https://www.pinterest.com/ecofurnify/'
                                  target='_blank'
                                  rel='noopener'
                                >
                                  <FaPinterestP className='social-icon' />
                                </a>
                                <a
                                  href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                                  target='_blank'
                                  rel='noopener'
                                >
                                  <FaInstagram className='social-icon' />
                                </a>
                                <a
                                  href='https://t.me/ecofurnify'
                                  target='_blank'
                                  rel='noopener'
                                >
                                  <FaTelegram className='social-icon' />
                                </a>
                                <a
                                  href='https://www.linkedin.com/company/ecofurnify'
                                  target='_blank'
                                  rel='noopener'
                                >
                                  <FaLinkedin className='social-icon' />
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End Footer Top */}
                          {/* Start Footer Center */}
                          <div className='footer-center d-none'>
                            <div className='footer-widgets-items'>
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Product</h5>
                                <ul className='footer-nav'>
                                  <li>
                                    <a href='#'>Shop Vendor</a>
                                  </li>
                                  <li>
                                    <a href='#'>Product House</a>
                                  </li>
                                  <li>
                                    <a href='#'>Categories</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Areas</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Cost</a>
                                  </li>
                                </ul>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Offer</h5>
                                <ul className='footer-nav'>
                                  <li>
                                    <a href='#'>Shop Vendor</a>
                                  </li>
                                  <li>
                                    <a href='#'>Product House</a>
                                  </li>
                                  <li>
                                    <a href='#'>Categories</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Areas</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Cost</a>
                                  </li>
                                </ul>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Information</h5>
                                <ul className='footer-nav'>
                                  <li>
                                    <a href='#'>Shop Vendor</a>
                                  </li>
                                  <li>
                                    <a href='#'>Product House</a>
                                  </li>
                                  <li>
                                    <a href='#'>Categories</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Areas</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Cost</a>
                                  </li>
                                </ul>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>About</h5>
                                <ul className='footer-nav'>
                                  <li>
                                    <a href='#'>Shop Vendor</a>
                                  </li>
                                  <li>
                                    <a href='#'>Product House</a>
                                  </li>
                                  <li>
                                    <a href='#'>Categories</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Areas</a>
                                  </li>
                                  <li>
                                    <a href='#'>Delivery Cost</a>
                                  </li>
                                </ul>
                              </div>
                              {/* End Footer Widget Single Item */}
                            </div>
                          </div>
                          {/* End Footer Center */}
                          {/* Start Footer Center */}
                          <div className='footer-center'>
                            <div className='footer-widgets-items'>
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Product</h5>
                                <h5
                                  className='collapsed-title collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#dividerId-1'
                                >
                                  Product
                                </h5>
                                <div
                                  id='dividerId-1'
                                  className='widget-collapse-body collapse'
                                >
                                  <ul className='footer-nav'>
                                    <li>
                                      <a href='contact.html'>Shop Vendor</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Product House</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Categories</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Areas</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Cost</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Offer</h5>
                                <h5
                                  className='collapsed-title collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#dividerId-2'
                                >
                                  Offer
                                </h5>
                                <div
                                  id='dividerId-2'
                                  className='widget-collapse-body collapse'
                                >
                                  <ul className='footer-nav'>
                                    <li>
                                      <a href='contact.html'>Shop Vendor</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Product House</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Categories</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Areas</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Cost</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>Information</h5>
                                <h5
                                  className='collapsed-title collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#dividerId-3'
                                >
                                  Information
                                </h5>
                                <div
                                  id='dividerId-3'
                                  className='widget-collapse-body collapse'
                                >
                                  <ul className='footer-nav'>
                                    <li>
                                      <a href='contact.html'>Shop Vendor</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Product House</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Categories</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Areas</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Cost</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* End Footer Widget Single Item */}
                              {/* Start Footer Widget Single Item */}
                              <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                                <h5 className='title'>About</h5>
                                <h5
                                  className='collapsed-title collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#dividerId-4'
                                >
                                  About
                                </h5>
                                <div
                                  id='dividerId-4'
                                  className='widget-collapse-body collapse'
                                >
                                  <ul className='footer-nav'>
                                    <li>
                                      <a href='contact.html'>Shop Vendor</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Product House</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Categories</a>
                                    </li>
                                    <li>
                                      <a href='contact.html'>Delivery Areas</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* End Footer Widget Single Item */}
                            </div>
                          </div>
                          {/* End Footer Center */}
                          {/* Start Footer Bottom */}
                          <div className='footer-bottom'>
                            <p className='copyright-text copyright-text--dark'>
                              &copy; 2023 Download More Products In{' '}
                              <a
                                href='https://petroskadeh.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Jaybusiness
                              </a>
                            </p>
                            <a href='#' className='payment-logo'>
                              <img
                                className='img-fluid'
                                src='assets/images/company-logo/payment-logo.png'
                                alt=''
                              />
                            </a>
                          </div>
                          {/* End Footer Bottom */}
                        </div>
                      </div>
                    </div>
                  </footer>
                  {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
                  {/* <!-- Scroll To button --> */}
                  <div
                    id='scroll-to-top'
                    className='scroll-to-top'
                    onClick={scrollToTop}
                  >
                    <span className='material-icons-outlined'>expand_less</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: Start Product Tab Item Section :::... --> */}
    </div>
  )
}

export default HomePage
