import React, { useState, useEffect } from 'react'
import '../styles/Account.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'

// dependencies
import { Link } from 'react-router-dom'

function Account() {
  // show profile Api
  const [profileData, setProfileData] = useState(null)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        // خواندن مقدار accessToken از sessionStorage
        const accessToken = sessionStorage.getItem('accessToken')

        // بررسی اگر accessToken وجود دارد
        if (!accessToken) {
          throw new Error('Access token is missing')
        }

        const response = await fetch('https://grayjayco.com/api/show-profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        setProfileData(data)
      } catch (error) {
        setError(error.message)
        console.log(error)
      }
    }

    fetchData()
  }, [])

  // handel change

  const handleFirstNameChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, first_name: e.target.value },
    }))
  }

  const handleLastNameChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, last_name: e.target.value },
    }))
  }

  const handleEmailChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, email: e.target.value },
    }))
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // بررسی وجود توکن دسترسی در sessionStorage
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      setIsLoggedIn(true)
    }
  }, [])

  const handleLogOut = () => {
    sessionStorage.removeItem('accessToken') // حذف توکن از sessionStorage
    setIsLoggedIn(false) // تغییر وضعیت به خارج شده
  }

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  {isLoggedIn ? (
                    <Link
                      to='/my-account'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Profile
                    </Link>
                  ) : (
                    <Link
                      to='/login-register'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Log In
                    </Link>
                  )}
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Hurry!</span> <br /> Get your
                    product now
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Account</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      {/* <!-- ...:::: Start Account Dashboard Section:::... --> */}
      <div className='account-dashboard section-fluid-270 section-top-gap-100'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3'>
              {/* <!-- Nav tabs --> */}
              <div className='dashboard_tab_button'>
                <ul role='tablist' className='nav flex-column dashboard-list'>
                  <li>
                    <a
                      href='#dashboard'
                      data-bs-toggle='tab'
                      className='nav-link btn btn-sm btn-default-outline active'
                    >
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a
                      href='#orders'
                      data-bs-toggle='tab'
                      className='nav-link btn btn-sm btn-default-outline'
                    >
                      Orders
                    </a>
                  </li>
                  <li>
                    <a
                      href='#account-details'
                      data-bs-toggle='tab'
                      className='nav-link btn btn-sm btn-default-outline'
                    >
                      Account details
                    </a>
                  </li>
                  <li>
                    <a
                      href='/'
                      className='nav-link btn btn-sm btn-default-outline'
                      onClick={handleLogOut}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              {/* <!-- Tab panes --> */}
              <div className='tab-content dashboard_content'>
                <div className='tab-pane fade show active' id='dashboard'>
                  <h4>Dashboard</h4>
                  <p>
                    From your account dashboard, you can easily check & view
                    your <a href='#'>recent orders</a>, manage your{' '}
                    <a href='#'>shipping and billing addresses</a>, and{' '}
                    <a href='#'>Edit your password and account details.</a>
                  </p>
                </div>
                <div className='tab-pane fade' id='orders'>
                  <h4>Orders</h4>
                  <div className='table_page table-responsive'>
                    <table>
                      <thead>
                        <tr>
                          <th>Order</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Total</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>May 10, 2018</td>
                          <td>
                            <span className='success'>Completed</span>
                          </td>
                          <td>$25.00 for 1 item</td>
                          <td>
                            <a href='cart.html' className='view'>
                              view
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>May 10, 2018</td>
                          <td>Processing</td>
                          <td>$17.00 for 1 item</td>
                          <td>
                            <a href='cart.html' className='view'>
                              view
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='tab-pane fade' id='account-details'>
                  <h3>Account details</h3>
                  <div className='login'>
                    <div className='login_form_container'>
                      <div className='account_login_form'>
                        <form action='#'>
                          <p>
                            Already have an account?{' '}
                            <a href='/'>Log in instead!</a>
                          </p>
                          <div className='input-radio'></div>
                          <br />
                          {profileData && (
                            <>
                              <div className='default-form-box mb-20'>
                                <label>First Name</label>
                                <input
                                  type='text'
                                  name='first-name'
                                  value={profileData.data.first_name || ''}
                                  onChange={handleFirstNameChange}
                                />
                              </div>
                              <div className='default-form-box mb-20'>
                                <label>Last Name</label>
                                <input
                                  type='text'
                                  name='last-name'
                                  value={profileData.data.last_name || ''}
                                  onChange={handleLastNameChange}
                                />
                              </div>
                              <div className='default-form-box mb-20'>
                                <label>Email</label>
                                <input
                                  type='text'
                                  name='email'
                                  value={profileData.data.email || ''}
                                  onChange={handleEmailChange}
                                />
                              </div>
                            </>
                          )}
                          {/* <div className='default-form-box mb-20'>
                            <label>Birthdate</label>
                            <input type='date' name='birthday' />
                          </div>
                          <span className='example'>(E.g.: 05/31/1970)</span> */}
                          <br />
                          <label className='checkbox-default' for='offer'>
                            <input type='checkbox' id='offer' />
                            <span>Receive offers from our partners</span>
                          </label>
                          <br />
                          <label
                            className='checkbox-default checkbox-default-more-text'
                            for='newsletter'
                          >
                            <input type='checkbox' id='newsletter' />
                            <span>
                              Sign up for our newsletter
                              <br />
                              <em>
                                You may unsubscribe at any moment. For that
                                purpose, please find our contact info in the
                                legal notice.
                              </em>
                            </span>
                          </label>
                          <div className='save_button mt-3'>
                            <button
                              className='btn btn-sm btn-default-outline'
                              type='submit'
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...:::: End Account Dashboard Section:::... --> */}

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default Account
