import React, { useState, useEffect } from 'react'
import '../styles/About.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import heartIcon from '../assets/images/icons/icon-heart-light.svg'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'
// import iconTrash from '../assets/images/icons/icon-trash.svg'

// dependencies
import { Link } from 'react-router-dom'

function About() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // بررسی وجود توکن دسترسی در sessionStorage
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  {isLoggedIn ? (
                    <Link
                      to='/my-account'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Profile
                    </Link>
                  ) : (
                    <Link
                      to='/login-register'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Log In
                    </Link>
                  )}
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>About Page</span>
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> About</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      {/* <!-- ...::: Strat About Section :::... --> */}
      <div className='about-section section-fluid-270 section-top-gap-100'>
        <div className='section-wrapper'>
          <div className='container-fluid'>
            <h1>Our Team</h1>
            {/* Person-1 */}
            <div className='about-page'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-10 col-lg-6 col-xl-4 col-xxl-5'>
                  <div className='section-content'>
                    <span className='title-tag m-0'>BEST DEAL FOREVER</span>
                    <h2 className='section-title-stylish'>
                      About <span>Ecofurnify</span> Best
                      <span>eCommerce </span> site.
                    </h2>
                    <h4>Name: Farsan Abdi</h4>
                    <h4>Age: 38 years old</h4>
                    <h4>Location: Tehran</h4>
                    <h4>Education: Master's degree</h4>
                    <h4>Place of Birth: Urmia</h4>
                    <p>
                      Work Experience: Former General Manager of a Neopan
                      Factory for 5 years, General Manager of Plywood Production
                      for 2 years, and General Manager of a Plastic Recycling
                      Factory for 3 years.
                    </p>
                    <p>
                      Currently, I am the owner of the Farsan Furniture brand,
                      where I engage in remodeling and producing decor. Personal
                      and Professional
                    </p>
                    <p>
                      Goals: Focusing on innovation in the carpentry and
                      recycling industries, particularly in repurposing old and
                      unusable items by combining woodworking, metalworking,
                      resin work, carving, and decor recycling. Additionally, I
                      possess the skill of lifeguarding. Interests and Hobbies:
                      Interested in football, swimming, nature trips, and
                      crafting unique decorations.
                    </p>
                    <p>
                      Company Name: Farsan Furniture Achievement: Remodeling and
                      presenting decor for the historical series "Khatun," which
                      is one of the most popular Iranian TV series.
                    </p>
                  </div>
                </div>
                <div className='col-10 col-lg-6 col-xl-7 col-xxl offset-xl-1'>
                  <div className='image'>
                    <img
                      src='assets/images/about/about-section-img.webp'
                      alt=''
                      className='img-size img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End */}
            {/* Person-2 */}
            <div className='about-page'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-10 col-lg-6 col-xl-4 col-xxl-5'>
                  <div className='section-content'>
                    <h4>Name: Atefeh Esmaeilimoghadam</h4>
                    <h4>Age: </h4>
                    <h4>Location: </h4>
                    <h4>Education: </h4>
                    <h4>Place of Birth: </h4>
                    <p>
                      She is a highly experienced professional with a passion
                      for entrepreneurship, sales, and market research. With 6
                      years of entrepreneurial experience, she has successfully
                      launched and managed her own ventures, gaining valuable
                      insights and achievements along the way.
                    </p>
                    <p>
                      In addition to her entrepreneurial background, she has
                      also spent 8 years working in sales and market research.
                      During this time, she has worked in various industries,
                      including technology and customer service. This diverse
                      experience has equipped her with a deep understanding of
                      human resource management, strategic planning, and
                      organizational performance improvement.
                    </p>
                    <p>
                      Throughout her career, she has developed a wide range of
                      skills, including leadership, decision-making, time
                      management, problem-solving, and interpersonal
                      communication. She is also highly skilled in teamwork and
                      collaboration, as she believes that working together with
                      others can lead to greater success.
                    </p>
                    <p>
                      She is passionate about continuous learning and growth,
                      always seeking out new opportunities to expand her
                      knowledge and skills. She is particularly interested in
                      the intersection of art, technology, and sustainable
                      development and strives to incorporate these values into
                      her work.
                    </p>
                  </div>
                </div>
                <div className='col-10 col-lg-6 col-xl-7 col-xxl offset-xl-1'>
                  <div className='image'>
                    <img
                      src='assets/images/about/about-section-img-3.webp'
                      alt=''
                      className='img-size img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End */}
            {/* Person-2 */}
            <div className='about-page'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-10 col-lg-6 col-xl-4 col-xxl-5'>
                  <div className='section-content'>
                    <h4>Name: Alireza Javadi</h4>
                    <h4>Age: 35 years old</h4>
                    <h4>Location: Mashhad</h4>
                    <h4>Education: Master's degree</h4>
                    <h4>Place of Birth: Mashhad</h4>
                    <p>
                      Work Experience: Former General Manager of a Neopan
                      Factory for 5 years, General Manager of Plywood Production
                      for 2 years, and General Manager of a Plastic Recycling
                      Factory for 3 years.
                    </p>
                    <p>
                      Currently, I am the owner of the Farsan Furniture brand,
                      where I engage in remodeling and producing decor. Personal
                      and Professional
                    </p>
                    <p>
                      Goals: Focusing on innovation in the carpentry and
                      recycling industries, particularly in repurposing old and
                      unusable items by combining woodworking, metalworking,
                      resin work, carving, and decor recycling. Additionally, I
                      possess the skill of lifeguarding. Interests and Hobbies:
                      Interested in football, swimming, nature trips, and
                      crafting unique decorations.
                    </p>
                    <p>
                      Company Name: Farsan Furniture Achievement: Remodeling and
                      presenting decor for the historical series "Khatun," which
                      is one of the most popular Iranian TV series.
                    </p>
                  </div>
                </div>
                <div className='col-10 col-lg-6 col-xl-7 col-xxl offset-xl-1'>
                  <div className='image'>
                    <img
                      src='assets/images/about/about-section-img-2.webp'
                      alt=''
                      className='img-size img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End */}
          </div>
        </div>
      </div>
      {/* <!-- ...::: End About Section :::... --> */}

      {/* <!-- ...::: Strat Subscribe Section :::... --> */}
      <div className='newsletter-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='newsletter-wrapper'>
            <div className='container-fluid'>
              <div className='col-12'>
                <div className='newsletter-area newsletter-area-style-2'>
                  <div className='content content-left'>
                    <h4 className='title'>Newsletter</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing. <br />{' '}
                      printing and typesetting industry.
                    </p>
                    <div className='newsletter-form'>
                      <input
                        type='email'
                        placeholder='Enter your email'
                        required
                      />
                      <button type='submit'>Subscribe</button>
                    </div>
                    <div className='image-shape image-shape-left'>
                      <img
                        className='img-fluid'
                        src='assets/images/icons/newsletter-paper-plane.svg'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='content content-right'>
                    <h4 className='title'>Download App</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing. <br />{' '}
                      printing and typesetting industry.
                    </p>
                    <div className='apps-btn'>
                      <a href='#'>
                        <img
                          className='img-fluid'
                          src='assets/images/icons/iphone-app-button.png'
                          alt=''
                        />
                      </a>
                      <a href='#'>
                        <img
                          className='img-fluid'
                          src='assets/images/icons/googleplay-app-button.png'
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='image-shape image-shape-right'>
                      <img
                        className='img-fluid'
                        src='assets/images/icons/newsletter-cloud-computing.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Subscribe Section :::... --> */}

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default About
