import React, { useState } from 'react'
import '../styles/ProductDetails.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'
// import iconTrash from '../assets/images/icons/icon-trash.svg'

// dependencies
import { Link } from 'react-router-dom'

// slider
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function ProductDetails() {
  // Slider

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  <Link
                    to='/login-register'
                    className='color-btn header-action-item header-action-wishlist'
                  >
                    Log In
                  </Link>
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='/cart' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Hurry!</span> <br /> Get your
                    product now
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Shop Page Left Sidebar</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      {/* <!-- ...::: Strat Product Gallery Section :::... --> */}
      <div className='product-gallery-info-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='product-gallery-info-wrapper'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xxl-8 col-lg-6'>
                  {/* <!-- Start Product Gallert - Tab Style --> */}
                  <div className='product-gallery product-gallery--style-tab'>
                    <div className='row flex-md-row flex-column-reverse'>
                      <div className='col-md-3'>
                        {/* Start Product Thumbnail */}
                        <ul className='product-thumbnail-image nav'>
                          <li className='nav-item'>
                            <button
                              className='nav-link active'
                              data-bs-toggle='tab'
                              data-bs-target='#img-1'
                              type='button'
                            >
                              <span className='thumb'>
                                <img
                                  className='img-fluid'
                                  src='assets/images/products/product-details/product-thumb-1.webp'
                                  alt=''
                                />
                              </span>
                            </button>
                          </li>
                          <li className='nav-item' role='presentation'>
                            <button
                              className='nav-link'
                              data-bs-toggle='tab'
                              data-bs-target='#img-2'
                              type='button'
                            >
                              <span className='thumb'>
                                <img
                                  className='img-fluid'
                                  src='assets/images/products/product-details/product-thumb-2.webp'
                                  alt=''
                                />
                              </span>
                            </button>
                          </li>
                          <li className='nav-item' role='presentation'>
                            <button
                              className='nav-link'
                              data-bs-toggle='tab'
                              data-bs-target='#img-3'
                              type='button'
                            >
                              <span className='thumb'>
                                <img
                                  className='img-fluid'
                                  src='assets/images/products/product-details/product-thumb-3.webp'
                                  alt=''
                                />
                              </span>
                            </button>
                          </li>
                        </ul>
                        {/* End Product Thumbnail */}
                      </div>
                      <div className='col-md-9'>
                        {/* Start Product Large Image */}
                        <div className='product-large-image tab-content'>
                          <div
                            className='tab-pane fade show active'
                            id='img-1'
                            role='tabpanel'
                          >
                            <div className='image'>
                              <img
                                className='img-fluid'
                                src='assets/images/products/product-details/product-large-1.webp'
                                alt=''
                              />
                            </div>
                          </div>
                          <div
                            className='tab-pane fade'
                            id='img-2'
                            role='tabpanel'
                          >
                            <div className='image'>
                              <img
                                className='img-fluid'
                                src='assets/images/products/product-details/product-large-2.webp'
                                alt=''
                              />
                            </div>
                          </div>
                          <div
                            className='tab-pane fade'
                            id='img-3'
                            role='tabpanel'
                          >
                            <div className='image'>
                              <img
                                className='img-fluid'
                                src='assets/images/products/product-details/product-large-3.webp'
                                alt=''
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Product Large Image */}
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Product Gallert - Tab Style --> */}
                </div>
                <div className='col-xxl-4 col-lg-6'>
                  {/* <!-- Start Product Content --> */}
                  <div className='product-content'>
                    <span className='catagory'>Women</span>
                    <h2 className='title'>Stylish women court gray 2DX m</h2>
                    <span className='author'>Design: kakakoli Fashion</span>
                    <div className='bottom'>
                      <ul className='review-star'>
                        <li className='fill'>
                          <span className='material-icons'>star</span>
                        </li>
                        <li className='fill'>
                          <span className='material-icons'>star</span>
                        </li>
                        <li className='fill'>
                          <span className='material-icons'>star</span>
                        </li>
                        <li className='fill'>
                          <span className='material-icons'>star</span>
                        </li>
                        <li className='fill'>
                          <span className='material-icons'>star_half</span>
                        </li>
                      </ul>
                      <a href='wishlist.html' className='wishlist'>
                        Add Wishlist
                      </a>
                    </div>
                    <span className='price'>
                      $550.00 <del>$650.00</del>
                    </span>
                    <div className='product-variables'>
                      {/* Start Product Single Variable */}
                      <div className='product-variable-color'>
                        <h6 className='title'>Color</h6>
                        <ul className='color-select'>
                          <li>
                            <label
                              className='checkbox-default'
                              htmlFor='color-red'
                            >
                              <input type='checkbox' id='color-red' />
                              <span>Red</span>
                            </label>
                          </li>
                          <li>
                            <label
                              className='checkbox-default'
                              htmlFor='color-green'
                            >
                              <input type='checkbox' id='color-green' />
                              <span>Green</span>
                            </label>
                          </li>
                          <li>
                            <label
                              className='checkbox-default'
                              htmlFor='color-blue'
                            >
                              <input type='checkbox' id='color-blue' />
                              <span>Blue</span>
                            </label>
                          </li>
                          <li>
                            <label
                              className='checkbox-default'
                              htmlFor='color-black'
                            >
                              <input type='checkbox' id='color-black' />
                              <span>Black</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      {/* End Product Single Variable */}
                      <ul className='variable-items'>
                        <li className='variable-single-items type-select'>
                          <select>
                            <option value='S'>Size: S</option>
                            <option value='M'>Size: M</option>
                            <option value='L'>Size: L</option>
                            <option value='XL' selected>
                              Size: XL
                            </option>
                            <option value='XXl'>Size: XXL</option>
                          </select>
                        </li>
                        <li className='variable-single-items'>
                          <div className='num-block skin-2'>
                            <div className='num-in'>
                              <span className='minus dis'></span>
                              <input
                                type='text'
                                className='in-num'
                                value='1'
                                readOnly
                              />
                              <span className='plus'></span>
                            </div>
                          </div>
                        </li>
                        <li className='variable-single-items'>
                          <a
                            href='cart.html'
                            className='btn btn-sm btn-default'
                          >
                            Add To Cart
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Product Content --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Product Gallery Section :::... --> */}

      {/* <!-- ...::: Strat Product Description Section :::... --> */}
      <div className='product-description-section  section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='product-description-wrapper'>
            <div className='container-fluid'>
              <div className='row justify-content-center'>
                <div className='col-xl-8 col-lg-10'>
                  <div className='product-description-content'>
                    <h6 className='title'>Description</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survivedLorem Ipsum is simply
                      dummy text of the printing and typesetting industry. Lorem
                      Ipsum has been{' '}
                    </p>

                    <p>
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book
                    </p>

                    <ul className='items-info-list'>
                      <li>There are many variations of passages</li>
                      <li>If you are going to use a passage of Lorem Ipsum.</li>
                      <li>The generated Lorem Ipsum is therefore</li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </li>
                      <li>
                        At vero eos et accusamus et iusto odio dignissimos
                      </li>
                    </ul>

                    <p>
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book
                    </p>

                    <div className='social-links'>
                      <span className='text'>Share:</span>
                      <div className='items'>
                        <a href='https://example.com/'>
                          <img
                            className='icon-svg'
                            src='assets/images/icons/icon-facebook-f-dark.svg'
                            alt=''
                          />
                        </a>
                        <a href='https://example.com/'>
                          <img
                            className='icon-svg'
                            src='assets/images/icons/icon-twitter-dark.svg'
                            alt=''
                          />
                        </a>
                        <a href='https://example.com/'>
                          <img
                            className='icon-svg'
                            src='assets/images/icons/icon-pinterest-p-dark.svg'
                            alt=''
                          />
                        </a>
                        <a href='https://example.com/'>
                          <img
                            className='icon-svg'
                            src='assets/images/icons/icon-dribbble-dark.svg'
                            alt=''
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Product Description Section :::... --> */}

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default ProductDetails
