import React, { useState, useEffect } from 'react'
import '../styles/CheckoutPage.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'


// dependencies
import { Link } from 'react-router-dom'

function CheckoutPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // بررسی وجود توکن دسترسی در sessionStorage
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  {isLoggedIn ? (
                    <Link
                      to='/my-account'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Profile
                    </Link>
                  ) : (
                    <Link
                      to='/login-register'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Log In
                    </Link>
                  )}
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Checkout Page</span>
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Checkout</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      <div className='checkout-section section-fluid-270 section-top-gap-100'>
        <div className='container-fluid'>
          <div className='row'>
            {/* <!-- User Quick Action Form --> */}
            <div className='col-12'>
              <div className='user-actions accordion'>
                <h3>
                  Returning customer?
                  <a
                    className='Returning'
                    href='#checkout_login'
                    data-bs-toggle='collapse'
                    aria-expanded='true'
                  >
                    Click here to login
                  </a>
                </h3>
                <div
                  id='checkout_login'
                  className='collapse'
                  data-bs-parent='#checkout_login'
                >
                  <div className='checkout_info'>
                    <p>
                      If you have shopped with us before, please enter your
                      details in the boxes below. If you are a new customer
                      please proceed to the Billing &amp; Shipping section.
                    </p>
                    <form action='#'>
                      <div className='form_group default-form-box'>
                        <label>
                          Username or email <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                      <div className='form_group default-form-box'>
                        <label>
                          Password <span>*</span>
                        </label>
                        <input type='password' />
                      </div>
                      <div className='form_group group_3 default-form-box'>
                        <button
                          className='btn btn-sm btn-radius btn-default'
                          type='submit'
                        >
                          Login
                        </button>
                        <label className='checkbox-default'>
                          <input type='checkbox' />
                          <span>Remember me</span>
                        </label>
                      </div>
                      <a href='#'>Lost your password?</a>
                    </form>
                  </div>
                </div>
              </div>
              <div className='user-actions accordion'>
                <h3>
                  Returning customer?
                  <a
                    className='Returning'
                    href='#checkout_coupon'
                    data-bs-toggle='collapse'
                    aria-expanded='true'
                  >
                    Click here to enter your code
                  </a>
                </h3>
                <div
                  id='checkout_coupon'
                  className='collapse checkout_coupon'
                  data-bs-parent='#checkout_coupon'
                >
                  <div className='checkout_info'>
                    <form action='#'>
                      <input placeholder='Coupon code' type='text' />
                      <button
                        className='btn btn-sm btn-radius btn-default'
                        type='submit'
                      >
                        Apply coupon
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- User Quick Action Form --> */}
          </div>
          {/* <!-- Start User Details Checkout Form --> */}
          <div className='checkout_form'>
            <div className='row'>
              <div className='col-lg-6 col-md-6'>
                <form action='#'>
                  <h3>Billing Details</h3>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='default-form-box'>
                        <label>
                          First Name <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='default-form-box'>
                        <label>
                          Last Name <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <label>Company Name</label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <label htmlFor='country'>
                          Country <span>*</span>
                        </label>
                        <select
                          className='country_option nice-select wide'
                          name='country'
                          id='country'
                        >
                          <option value='2'>Bangladesh</option>
                          <option value='3'>Algeria</option>
                          <option value='4'>Afghanistan</option>
                          <option value='5'>Ghana</option>
                          <option value='6'>Albania</option>
                          <option value='7'>Bahrain</option>
                          <option value='8'>Colombia</option>
                          <option value='9'>Dominican Republic</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <label>
                          Street address <span>*</span>
                        </label>
                        <input
                          placeholder='House number and street name'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <input
                          placeholder='Apartment, suite, unit etc. (optional)'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <label>
                          Town / City <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='default-form-box'>
                        <label>
                          State / County <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='default-form-box'>
                        <label>
                          Phone<span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='default-form-box'>
                        <label>
                          {' '}
                          Email Address <span>*</span>
                        </label>
                        <input type='text' />
                      </div>
                    </div>
                    <div className='col-12'>
                      <label
                        className='checkbox-default'
                        htmlFor='newAccount'
                        data-bs-toggle='collapse'
                        data-bs-target='#newAccountPassword'
                      >
                        <input type='checkbox' id='newAccount' />
                        <span>Create an account?</span>
                      </label>
                      <div
                        id='newAccountPassword'
                        className='collapse mt-3'
                        data-parent='#newAccountPassword'
                      >
                        <div className='card-body1 default-form-box'>
                          <label>
                            {' '}
                            Account password <span>*</span>
                          </label>
                          <input placeholder='password' type='password' />
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <label
                        className='checkbox-default'
                        htmlFor='newShipping'
                        data-bs-toggle='collapse'
                        data-bs-target='#anotherShipping'
                      >
                        <input type='checkbox' id='newShipping' />
                        <span>Ship to a different address?</span>
                      </label>

                      <div
                        id='anotherShipping'
                        className='collapse mt-3'
                        data-parent='#anotherShipping'
                      >
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='default-form-box'>
                              <label>
                                First Name <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='default-form-box'>
                              <label>
                                Last Name <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='default-form-box'>
                              <label>Company Name</label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='select_form_select default-form-box'>
                              <label htmlFor='country_name'>
                                Country <span>*</span>
                              </label>
                              <select
                                className='country_option niceselect_option wide'
                                name='country'
                                id='country_name'
                              >
                                <option value='2'>Bangladesh</option>
                                <option value='3'>Algeria</option>
                                <option value='4'>Afghanistan</option>
                                <option value='5'>Ghana</option>
                                <option value='6'>Albania</option>
                                <option value='7'>Bahrain</option>
                                <option value='8'>Colombia</option>
                                <option value='9'>Dominican Republic</option>
                              </select>
                            </div>
                          </div>

                          <div className='col-12'>
                            <div className='default-form-box'>
                              <label>
                                Street address <span>*</span>
                              </label>
                              <input
                                placeholder='House number and street name'
                                type='text'
                              />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='default-form-box'>
                              <input
                                placeholder='Apartment, suite, unit etc. (optional)'
                                type='text'
                              />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='default-form-box'>
                              <label>
                                Town / City <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className='default-form-box'>
                              <label>
                                State / County <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='default-form-box'>
                              <label>
                                Phone <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='default-form-box'>
                              <label>
                                Email Address <span>*</span>
                              </label>
                              <input type='text' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 mt-3'>
                      <div className='order-notes'>
                        <label htmlFor='order_note'>Order Notes</label>
                        <textarea
                          id='order_note'
                          placeholder='Notes about your order, e.g. special notes for delivery.'
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col-lg-6 col-md-6'>
                <form action='#'>
                  <h3>Your order</h3>
                  <div className='order_table table-responsive'>
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {' '}
                            Handbag fringilla <strong> × 2</strong>
                          </td>
                          <td> $165.00</td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            Handbag justo <strong> × 2</strong>
                          </td>
                          <td> $50.00</td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            Handbag elit <strong> × 2</strong>
                          </td>
                          <td> $50.00</td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            Handbag Rutrum <strong> × 1</strong>
                          </td>
                          <td> $50.00</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Cart Subtotal</th>
                          <td>$215.00</td>
                        </tr>
                        <tr>
                          <th>Shipping</th>
                          <td>
                            <strong>$5.00</strong>
                          </td>
                        </tr>
                        <tr className='order_total'>
                          <th>Order Total</th>
                          <td>
                            <strong>$220.00</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className='payment_method'>
                    <div className='panel-default'>
                      <label
                        className='checkbox-default'
                        htmlFor='currencyCod'
                        data-bs-toggle='collapse'
                        data-bs-target='#methodCod'
                      >
                        <input
                          type='radio'
                          id='currencyCod'
                          name='payment'
                          defaultChecked
                        />
                        <span>Cash on Delivery</span>
                      </label>
                      <div
                        id='methodCod'
                        className='collapse'
                        data-bs-parent='#methodCod'
                      >
                        <div className='card-body1'>
                          <p>
                            Please send a check to Store Name, Store Street,
                            Store Town, Store State / County, Store Postcode.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='panel-default'>
                      <label
                        className='checkbox-default'
                        htmlFor='currencyPaypal'
                        data-bs-toggle='collapse'
                        data-bs-target='#methodPaypal'
                      >
                        <input
                          type='radio'
                          id='currencyPaypal'
                          name='payment'
                        />
                        <span>PayPal</span>
                      </label>
                      <div
                        id='methodPaypal'
                        className='collapse'
                        data-bs-parent='#methodPaypal'
                      >
                        <div className='card-body1'>
                          <p>
                            Pay via PayPal; you can pay with your credit card if
                            you don’t have a PayPal account.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='order_button'>
                      <button
                        className='btn btn-sm btn-radius btn-default'
                        type='submit'
                      >
                        Proceed to PayPal
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default CheckoutPage
