import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/Login.css'
import { useHistory } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import heartIcon from '../assets/images/icons/icon-heart-light.svg'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'

// dependencies
import { Link } from 'react-router-dom'

function Login() {
  // Login
  const [usernameOrEmail, setUsernameOrEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const handleUsernameOrEmailChange = (e) => {
    setUsernameOrEmail(e.target.value)
  }

  const handleLoginPasswordChange = (e) => {
    setLoginPassword(e.target.value)
  }

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked)
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault()
  }

  // Register
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleRegisterEmailChange = (e) => {
    setRegisterEmail(e.target.value)
  }

  const handleRegisterPasswordChange = (e) => {
    setRegisterPassword(e.target.value)
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handRegisterSubmit = (e) => {
    e.preventDefault()
  }

  // Register Handle
  const history = useHistory()

  const handelRegister = () => {
    if (registerPassword !== confirmPassword) {
      alert('The entered password is not the same as repeating it')
      return
    }

    const data = JSON.stringify({
      email: registerEmail,
      password: registerPassword,
      first_name: 'shahab',
      last_name: 'boush',
    })

    fetch('https://grayjayco.com/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 422) {
          throw new Error('The email has already been taken')
        } else {
          throw new Error('Login failed with status: ' + response.status)
        }
      })
      .then((data) => {
        alert('Register successful!')
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  // Log in handle

  const handleLogin = () => {
    const data = JSON.stringify({
      email: usernameOrEmail,
      password: loginPassword,
    })

    fetch('https://grayjayco.com/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 422) {
          throw new Error('Email or password is incorrect')
        } else {
          throw new Error('Login failed with status: ' + response.status)
        }
      })
      .then((data) => {
        // اطمینان حاصل کنید که data و data.data.access_token وجود دارند
        if (data && data.data && data.data.access_token) {
          // ذخیره‌سازی توکن در sessionStorage
          sessionStorage.setItem('accessToken', data.data.access_token)
          // console.log('Access Token:', data.data.access_token)
          alert('Login successful!')
          // ریدایرکت به صفحه اصلی پس از ورود موفق
          history.push('/')
        } else {
          // اگر access_token در پاسخ وجود ندارد، یک خطای مناسب نمایش دهید
          throw new Error('Access token is missing in the response')
        }
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  <Link
                    to='/login-register'
                    className='color-btn header-action-item header-action-wishlist'
                  >
                    Log In
                  </Link>
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='/cart' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Hurry!</span> <br /> Get your
                    product now
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Login and Register Page </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      {/* <!-- ...:::: Start Customer Login Section :::... --> */}
      <div className='customer-login section-fluid-270 section-top-gap-100'>
        <div className='container-fluid'>
          <div className='row'>
            {/* <!--login area start--> */}
            <div className='col-lg-6 col-md-6'>
              <div className='account_form'>
                <h3>Login</h3>
                <form onSubmit={handleLoginSubmit}>
                  <div className='default-form-box'>
                    <label>
                      Username or email <span>*</span>
                    </label>
                    <input
                      type='text'
                      value={usernameOrEmail}
                      onChange={handleUsernameOrEmailChange}
                    />
                  </div>
                  <div className='default-form-box'>
                    <label>
                      Password <span>*</span>
                    </label>
                    <input
                      type='password'
                      value={loginPassword}
                      onChange={handleLoginPasswordChange}
                    />
                  </div>
                  <div className='login_submit'>
                    <button
                      className='btn btn-sm btn-radius btn-default mb-4'
                      type='submit'
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                    <label className='checkbox-default mb-4' htmlFor='offer'>
                      <input
                        type='checkbox'
                        id='offer'
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      <span>Remember me</span>
                    </label>
                    <a href='/'>Lost your password?</a>
                  </div>
                </form>
              </div>
            </div>
            {/* <!--login area End--> */}

            {/* <!--register area start--> */}
            <div className='col-lg-6 col-md-6'>
              <div
                className='account_form'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <h3>Register</h3>
                <form onSubmit={handRegisterSubmit}>
                  <div className='default-form-box'>
                    <label>
                      Email address <span>*</span>
                    </label>
                    <input
                      type='text'
                      value={registerEmail}
                      onChange={handleRegisterEmailChange}
                    />
                  </div>
                  <div className='default-form-box'>
                    <label>
                      Password <span>*</span>
                    </label>
                    <input
                      type='password'
                      value={registerPassword}
                      onChange={handleRegisterPasswordChange}
                    />
                  </div>
                  <div className='default-form-box'>
                    <label>
                      Confirm Password <span>*</span>
                    </label>
                    <input
                      type='password'
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>
                  <div className='login_submit'>
                    <button
                      className='btn btn-sm btn-radius btn-default'
                      type='submit'
                      onClick={handelRegister}
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!--register area end--> */}
          </div>
        </div>
      </div>
      {/* <!-- ...:::: End Customer Login Section :::... --> */}

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default Login
