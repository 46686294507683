import './App.css'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import HomePage from './pages/HomePage'
import ShopPage from './pages/ShopPage'
import About from './pages/About'
import ProductDetails from './pages/ProductDetails'
import Login from './pages/Login'
import Account from './pages/Account'
import FaqPage from './pages/FaqPage'
import Contact from './pages/Contact'
import CheckoutPage from './pages/CheckoutPage'
import Cart from './pages/Cart'


function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/shop-page' component={ShopPage} />
          <Route path='/about' component={About} />
          <Route path='/product-detail' component={ProductDetails} />
          <Route path='/login-register' component={Login} />
          <Route path='/my-account' component={Account} />
          <Route path='/faq' component={FaqPage} />
          <Route path='/contact' component={Contact} />
          <Route path='/checkout' component={CheckoutPage} />
          <Route path='/cart' component={Cart} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
