import React, { useState, useEffect } from 'react'
import '../styles/Cart.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import heartIcon from '../assets/images/icons/icon-heart-light.svg'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'
// import iconTrash from '../assets/images/icons/icon-trash.svg'

// dependencies
import { Link } from 'react-router-dom'

function Cart() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // بررسی وجود توکن دسترسی در sessionStorage
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  {isLoggedIn ? (
                    <Link
                      to='/my-account'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Profile
                    </Link>
                  ) : (
                    <Link
                      to='/login-register'
                      className='color-btn header-action-item header-action-wishlist'
                    >
                      Log In
                    </Link>
                  )}
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='/cart' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}

      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Checkout Page</span>
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Checkout</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}

      {/* <!-- ...:::: Start Cart Section:::... --> */}
      <div className='cart-section section-fluid-270 section-top-gap-100'>
        {/* <!-- Start Cart Table --> */}
        <div className='cart-table-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='table_desc'>
                  <div className='table_page table-responsive'>
                    <table>
                      {/* <!-- Start Cart Table Head --> */}
                      <thead>
                        <tr>
                          <th className='product_remove'>Delete</th>
                          <th className='product_thumb'>Image</th>
                          <th className='product_name'>Product</th>
                          <th className='product-price'>Price</th>
                          <th className='product_quantity'>Quantity</th>
                          <th className='product_total'>Total</th>
                        </tr>
                      </thead>
                      {/* <!-- End Cart Table Head --> */}
                      <tbody>
                        {/* <!-- Start Cart Single Item--> */}
                        <tr>
                          <td className='product_remove'>
                            <a href='#'>
                              <img
                                src='assets/images/icons/icon-trash.svg'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_thumb'>
                            <a href='product-details-default.html'>
                              <img
                                src='assets/images/products/small/product-small-1.webp'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_name'>
                            <a href='product-details-default.html'>
                              Handbag fringilla
                            </a>
                          </td>
                          <td className='product-price'>$65.00</td>
                          <td className='product_quantity'>
                            <label>Quantity</label>
                            <input
                              min='1'
                              max='100'
                              defaultValue='1'
                              type='number'
                            />
                          </td>
                          <td className='product_total'>$130.00</td>
                        </tr>
                        {/* <!-- End Cart Single Item--> */}
                        {/* <!-- Start Cart Single Item--> */}
                        <tr>
                          <td className='product_remove'>
                            <a href='#'>
                              <img
                                src='assets/images/icons/icon-trash.svg'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_thumb'>
                            <a href='product-details-default.html'>
                              <img
                                src='assets/images/products/small/product-small-2.webp'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_name'>
                            <a href='product-details-default.html'>
                              Handbags justo
                            </a>
                          </td>
                          <td className='product-price'>$90.00</td>
                          <td className='product_quantity'>
                            <label>Quantity</label>
                            <input
                              min='1'
                              max='100'
                              defaultValue='1'
                              type='number'
                            />
                          </td>
                          <td className='product_total'>$180.00</td>
                        </tr>
                        {/* <!-- End Cart Single Item--> */}
                        {/* <!-- Start Cart Single Item--> */}
                        <tr>
                          <td className='product_remove'>
                            <a href='#'>
                              <img
                                src='assets/images/icons/icon-trash.svg'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_thumb'>
                            <a href='product-details-default.html'>
                              <img
                                src='assets/images/products/small/product-small-3.webp'
                                alt=''
                              />
                            </a>
                          </td>
                          <td className='product_name'>
                            <a href='product-details-default.html'>
                              Handbag elit
                            </a>
                          </td>
                          <td className='product-price'>$80.00</td>
                          <td className='product_quantity'>
                            <label>Quantity</label>
                            <input
                              min='1'
                              max='100'
                              defaultValue='1'
                              type='number'
                            />
                          </td>
                          <td className='product_total'>$160.00</td>
                        </tr>
                        {/* <!-- End Cart Single Item--> */}
                      </tbody>
                    </table>
                  </div>
                  <div className='cart_submit'>
                    <button
                      className='btn btn-sm btn-radius btn-default'
                      type='submit'
                    >
                      update cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Cart Table --> */}
        {/* <!-- Start Coupon Start --> */}
        <div className='coupon_area'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-6 col-md-6'>
                <div className='coupon_code left'>
                  <h3>Coupon</h3>
                  <div className='coupon_inner'>
                    <p>Enter your coupon code if you have one.</p>
                    <input
                      className='mb-2'
                      placeholder='Coupon code'
                      type='text'
                    />
                    <button
                      type='submit'
                      className='btn btn-sm btn-radius btn-default'
                    >
                      Apply coupon
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
                <div className='coupon_code right'>
                  <h3>Cart Totals</h3>
                  <div className='coupon_inner'>
                    <div className='cart_subtotal'>
                      <p>Subtotal</p>
                      <p className='cart_amount'>$215.00</p>
                    </div>
                    <div className='cart_subtotal'>
                      <p>Shipping</p>
                      <p className='cart_amount'>
                        <span>Flat Rate:</span> $255.00
                      </p>
                    </div>
                    <a href='#'>Calculate shipping</a>

                    <div className='cart_subtotal'>
                      <p>Total</p>
                      <p className='cart_amount'>$215.00</p>
                    </div>
                    <div className='checkout_btn'>
                      <a href='#' className='btn btn-sm btn-radius btn-default'>
                        Proceed to Checkout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Coupon Start --> */}
      </div>
      {/* <!-- ...:::: End Cart Section:::... --> */}

      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}
    </div>
  )
}

export default Cart
