import React, { useState } from 'react'
import '../styles/ShopPage.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-icons/iconfont/material-icons.css'
import {
  FaTwitter,
  FaPinterestP,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
} from 'react-icons/fa'

//Scripts
import '../assets/js/vendor/modernizr-3.11.2.min.js'
import '../assets/js/vendor/jquery-3.6.0.min.js'
import '../assets/js/vendor/bootstrap.bundle.min.js'
import '../assets/js/vendor/jquery-ui.min.js'

//Vendor CSS
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/material-icons.css'
import '../assets/css/vendor/jquery-ui.min.css'
import '../assets/css/style.css'

// Icons
import logoImage from '../assets/images/logo/logo-dark-theme.png'
import heartIcon from '../assets/images/icons/icon-heart-light.svg'
import bagIcon from '../assets/images/icons/icon-shopping-bag-light.svg'
// import iconTrash from '../assets/images/icons/icon-trash.svg'


// dependencies
import { Link } from 'react-router-dom'


function ShopPage() {
  //

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      {/* <!-- .....:::::: Start Header Section - Dark Header :::::.... --> */}
      <header className='header-section pos-absolute dark-bg sticky-header d-none d-lg-block section-fluid-270'>
        <div className='header-wrapper pos-relative'>
          <div className='container-fluid'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-auto'>
                {/* <!-- Start Header Logo --> */}
                <Link to='/' className='header-logo'>
                  <img className='img-fluid' src={logoImage} alt='Logo' />
                </Link>
                {/* <!-- End Header Logo --> */}
              </div>
              <div className='col-auto d-flex align-items-center'>
                <div className='header-event'>
                  {/* <!-- Start Menu event --> */}
                  <div className='menu-event dropdown'>
                    <button
                      className='main-menu-event dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src='assets/images/icons/icon-open-menu.svg'
                        alt=''
                      />
                      <span>Menu</span>
                      <img
                        src='assets/images/icons/icon-arrow-drop-down.svg'
                        alt=''
                      />
                    </button>
                    <ul className='mainmenu-nav dropdown-menu'>
                      <li className='menu-items'>
                        <a href='/'>
                          Home <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/shop-page'>
                          Shop <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/about'>
                          About <span class='material-icons'>arrow_right</span>
                        </a>
                      </li>
                      <li className='menu-items'>
                        <a href='/contact'>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className='search-event'>
                    <input
                      className='header-search'
                      type='search'
                      placeholder='Search'
                    />
                    <button className='header-search-btn' type='submit'>
                      <img src='assets/images/icons/icon-search.svg' alt='' />
                    </button>
                  </div>
                  {/* <!-- End Menu event --> */}
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-action'>
                  <Link
                    to='/login-register'
                    className='color-btn header-action-item header-action-wishlist'
                  >
                    Log In
                  </Link>
                  <button className='header-action-item header-action-wishlist'>
                    <img src={bagIcon} alt='Cart' />
                    <Link to='/cart' className='item-count item-count--dark'>
                      Cart
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- .....:::::: End Header Section - Dark Header :::::.... --> */}
      {/* <!-- ...::: Start Breadcrumb Section :::... --> */}
      <div className='breadcrumb-section'>
        <div className='box-wrapper'>
          <div className='breadcrumb-wrapper breadcrumb-wrapper--style-1 pos-relative'>
            <div className='breadcrumb-bg'>
              <img
                src='assets/images/breadcrumb/breadcrumb-img-shop-page.webp'
                alt=''
              />
            </div>
            <div className='breadcrumb-content section-fluid-270'>
              <div className='breadcrumb-wrapper'>
                <div className='content'>
                  <span className='title-tag'>BEST DEAL FOREVER</span>
                  <h2 className='title'>
                    <span className='text-mark'>Hurry!</span> <br /> Get your
                    product now
                  </h2>
                </div>
                <ul className='breadcrumb-nav'>
                  <li>
                    <a href='shop-grid-sidebar-left.html'>Shop</a>
                  </li>
                  <li> Shop Page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...::: End Breadcrumb Section :::... --> */}
      {/* <!-- ...:::: Start Shop List Section:::... --> */}
      <div className='shop-list-section section-fluid-270 section-top-gap-100'>
        <div className='box-wrapper'>
          <div className='shop-list-wrapper'>
            <div className='container-fluid'>
              <div className='row flex-column-reverse flex-lg-row'>
                <div className='col-xl-3 col-lg-3'>
                  {/* <!-- Start Sidebar Area --> */}
                  <div className='siderbar-section'>
                    {/* <!-- Start Single Sidebar Widget --> */}
                    <div className='sidebar-single-widget'>
                      <h6 className='sidebar-title title-border title-border'>
                        Categories
                      </h6>
                      <div className='sidebar-content'>
                        <div className='filter-type-select'>
                          <ul>
                            <li>
                              <label className='checkbox-default' htmlFor='men'>
                                <input type='checkbox' id='men' />
                                <span>Sofa</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='women'
                              >
                                <input type='checkbox' id='women' />
                                <span>Chair</span>
                              </label>
                            </li>
                            <li>
                              <label className='checkbox-default' htmlFor='kid'>
                                <input type='checkbox' id='kid' />
                                <span>Bed</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='fashion'
                              >
                                <input type='checkbox' id='fashion' />
                                <span>Table</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='new-arrival'
                              >
                                <input type='checkbox' id='new-arrival' />
                                <span>New Arrival</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Single Sidebar Widget --> */}

                    {/* /<!-- Start Single Sidebar Widget --> */}
                    <div className='sidebar-single-widget'>
                      <h6 className='sidebar-title title-border'>Color</h6>
                      <div className='sidebar-content'>
                        <div className='filter-type-select'>
                          <ul>
                            <li>
                              <label className='checkbox-default' htmlFor='red'>
                                <input type='checkbox' id='red' />
                                <span>Red</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='green'
                              >
                                <input type='checkbox' id='green' />
                                <span>Green</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='blue'
                              >
                                <input type='checkbox' id='blue' />
                                <span>Blue</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='black'
                              >
                                <input type='checkbox' id='black' />
                                <span>Black</span>
                              </label>
                            </li>
                            <li>
                              <label
                                className='checkbox-default'
                                htmlFor='pink'
                              >
                                <input type='checkbox' id='pink' />
                                <span>Pink</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Single Sidebar Widget --> */}

                    {/* <!-- Start Single Sidebar Widget --> */}
                    <div className='sidebar-single-widget'>
                      <h6 className='sidebar-title title-border'>
                        FILTER BY PRICE
                      </h6>
                      <div className='sidebar-content'>
                        <input
                          type='text'
                          className='js-range-slider'
                          name='my_range'
                          value=''
                          data-type='double'
                          data-min='0'
                          data-max='7000'
                          data-from='500'
                          data-to='5000'
                        />
                      </div>
                    </div>
                    {/* <!-- End Single Sidebar Widget --> */}

                    {/* <!-- Start Single Sidebar Widget --> */}
                    <div className='sidebar-single-widget'>
                      <div className='sidebar-content'>
                        <div className='banner-animate--flash'>
                          <a
                            href=''
                            className='sidebar-banner img-responsive'
                          >
                            <img
                              src='assets/images/banner/banner-sidebar.webp'
                              alt=''
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Single Sidebar Widget --> */}

                    {/* <!-- Start Single Sidebar Widget --> */}
                    <div className='sidebar-single-widget'>
                      <h6 className='sidebar-title title-border'>Tags</h6>
                      <div className='sidebar-content'>
                        <ul className='tag-link'>
                          <li>
                            <a href='/'>Home</a>
                          </li>
                          <li>
                            <a href='/'>Chair</a>
                          </li>
                          <li>
                            <a href='/'>Bed</a>
                          </li>
                          <li>
                            <a href='/'>Table</a>
                          </li>
                          <li>
                            <a href='/'>Sofa</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- End Single Sidebar Widget --> */}
                  </div>
                </div>
                <div className='col-xl-8 offset-xl-1 col-lg-9'>
                  {/* ------- */}
                  <ul className='product-shop-filter-info'>
                    <li className='prduct-item-traking'>
                      <span>total 08 of 40</span>
                    </li>
                    <li className='prduct-item-filter'>
                      <select name='choice'>
                        <option value='first'>New Arrival</option>
                        <option value='second' selected>
                          Featured
                        </option>
                        <option value='third'>Popular</option>
                      </select>
                    </li>
                  </ul>
                  {/* ------- */}
                  <div className='product-shop-list-items'>
                    <div className='row mb-n25'>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-1.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-2.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-3.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-4.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-5.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-6.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-7.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      {/* <!-- Start Product Single Item - Style 1 --> */}
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-8.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      {/* <!-- End Product Single Item - Style 1 --> */}
                      {/* <!-- Start Product Single Item - Style 1 --> */}
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-9.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      {/* <!-- End Product Single Item - Style 1 --> */}
                      {/* <!-- Start Product Single Item - Style 1 --> */}
                      <div className='col-md-6 col-12 mb-25'>
                        {/* <!-- Start Product Single Item - Style 1 --> */}
                        <div className='product-single-item-style-1'>
                          <a
                            href=''
                            className='image img-responsive'
                          >
                            <img
                              className='img-fluid'
                              src='assets/images/products/default/product-default-style-1-img-10.webp'
                              alt=''
                            />
                            <ul className='tooltip-tag-items'>
                              {/* <li className='color-green'>15%</li> */}
                            </ul>
                          </a>
                          <div className='content'>
                            <div className='top'>
                              {/* <span className='catagory'>MEN</span> */}
                              <h4 className='title'>
                                {/* <a href='product-details-default.html'>
                                  Man's Outdoor Shirt
                                </a> */}
                              </h4>
                              {/* <span className='price'>
                                $355.00 <del>$400.00</del>
                              </span> */}
                            </div>
                            <div className='bottom'>
                              <ul className='review-star'>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>star</span>
                                </li>
                                <li className='fill'>
                                  <span className='material-icons'>
                                    star_half
                                  </span>
                                </li>
                              </ul>
                              <div className='product-event-items'>
                                <a href='cart.html' className='btn cart-btn'>
                                  Contact Us
                                </a>
                                <a
                                  href='wishlist.html'
                                  className='btn wishlist-btn'
                                >
                                  <span className='material-icons'>
                                    favorite_border
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Product Single Item - Style 1 --> */}
                      </div>
                      {/* <!-- End Product Single Item - Style 1 --> */}
                    </div>
                  </div>
                  {/* <!-- Start Pagination --> */}
                  <div className='d-flex justify-content-center'>
                    <ul className='page-pagination'>
                      <li className='icon-direction icon-direction--prev'>
                        <a href='#'>
                          <span className='material-icons-outlined'>
                            arrow_left
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href='#'>1</a>
                      </li>
                      <li>
                        <a href='#'>2</a>
                      </li>
                      <li>
                        <a href='#'>3</a>
                      </li>
                      <li>...</li>
                      <li>
                        <a href='#'>10</a>
                      </li>
                      <li className='icon-direction icon-direction--next'>
                        <a href='#'>
                          <span className='material-icons-outlined'>
                            arrow_right
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- End Pagination --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ...:::: End Shop List Section:::... --> */}
      {/* <!-- ...::: Start Footer Section - Footer Dark :::... --> */}
      <footer className='footer-section footer-section-style-2 section-top-gap-120'>
        <div className='box-wrapper'>
          <div className='footer-wrapper section-fluid-270'>
            <div className='container-fluid'>
              {/* Start Footer Top */}
              <div className='footer-top'>
                <div className='footer-top-left'>
                  <div className='footer-contact-items'>
                    <a className='icon-left' href='tel:+12345678910'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-ios-call-dark.svg'
                        alt=''
                      />
                      +123 4567 8910
                    </a>
                    <a className='icon-left' href='mailto:demo@example.com'>
                      <img
                        className='icon-svg'
                        src='assets/images/icons/icon-mail-open-dark.svg'
                        alt=''
                      />
                      info@ecofurnify.com
                    </a>
                  </div>
                </div>
                <div className='footer-top-right'>
                  <div className='footer-social'>
                    <a
                      href='https://twitter.com/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTwitter className='social-icon' />
                    </a>
                    <a
                      href='https://www.pinterest.com/ecofurnify/'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaPinterestP className='social-icon' />
                    </a>
                    <a
                      href='https://instagram.com/ecofurnifyy?igshid=OGQ5ZDc2ODk2ZA=='
                      target='_blank'
                      rel='noopener'
                    >
                      <FaInstagram className='social-icon' />
                    </a>
                    <a
                      href='https://t.me/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaTelegram className='social-icon' />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/ecofurnify'
                      target='_blank'
                      rel='noopener'
                    >
                      <FaLinkedin className='social-icon' />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer Top */}
              {/* Start Footer Center */}
              <div className='footer-center d-none'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <ul className='footer-nav'>
                      <li>
                        <a href='#'>Shop Vendor</a>
                      </li>
                      <li>
                        <a href='#'>Product House</a>
                      </li>
                      <li>
                        <a href='#'>Categories</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Areas</a>
                      </li>
                      <li>
                        <a href='#'>Delivery Cost</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Center */}
              <div className='footer-center'>
                <div className='footer-widgets-items'>
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Product</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-1'
                    >
                      Product
                    </h5>
                    <div
                      id='dividerId-1'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Offer</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-2'
                    >
                      Offer
                    </h5>
                    <div
                      id='dividerId-2'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>Information</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-3'
                    >
                      Information
                    </h5>
                    <div
                      id='dividerId-3'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Cost</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                  {/* Start Footer Widget Single Item */}
                  <div className='footer-widgets-single-item footer-widgets-single-item--dark'>
                    <h5 className='title'>About</h5>
                    <h5
                      className='collapsed-title collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target='#dividerId-4'
                    >
                      About
                    </h5>
                    <div
                      id='dividerId-4'
                      className='widget-collapse-body collapse'
                    >
                      <ul className='footer-nav'>
                        <li>
                          <a href='contact.html'>Shop Vendor</a>
                        </li>
                        <li>
                          <a href='contact.html'>Product House</a>
                        </li>
                        <li>
                          <a href='contact.html'>Categories</a>
                        </li>
                        <li>
                          <a href='contact.html'>Delivery Areas</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Footer Widget Single Item */}
                </div>
              </div>
              {/* End Footer Center */}
              {/* Start Footer Bottom */}
              <div className='footer-bottom'>
                <p className='copyright-text copyright-text--dark'>
                  &copy; 2023 Download More Products In{' '}
                  <a
                    href='https://petroskadeh.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jaybusiness
                  </a>
                </p>
                <a href='#' className='payment-logo'>
                  <img
                    className='img-fluid'
                    src='assets/images/company-logo/payment-logo.png'
                    alt=''
                  />
                </a>
              </div>
              {/* End Footer Bottom */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- ...::: End Footer Section Section - Footer Dark :::... --> */}

      {/* <!-- Scroll To button --> */}
      <div id='scroll-to-top' className='scroll-to-top' onClick={scrollToTop}>
        <span className='material-icons-outlined'>expand_less</span>
      </div>
    </div>
  )
}

export default ShopPage
